















































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => "",
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      servicesAdded: false,
      error: 200 as number,
      services: [],
      currency: "",
    });

    const model = reactive({
      services: [] as any,
    });

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/company-additive-template`)
        .then(({ data: { templates } }) => {
          state.services = templates.map((t: any) => ({
            hasQuantity: t.hasQuantity,
            name: t.name,
            unit: t.unit,
            price: t.price,
            isEnabled: false,
            quantity: 0,
            total: 0,
            paid: 0,
            leftToPay: 0,
            paymentDate: null,
          }));
          state.servicesAdded = templates && templates.length ? true : false;
        })
        .catch((e) => console.log(e));
    };

    onMounted(fetchData);

    watch(
      () => state.services,
      () => (props.value.additives = state.services),
      { deep: true }
    );

    watch(
      () => props.value,
      () =>
        (props.value.totalServicePrice = props.value.additives.reduce(
          (prev: any, cur: any) => prev + cur.total,
          0
        )),
      { deep: true }
    );

    return { state, model };
  },
});
